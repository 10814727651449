import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateListUnsplash(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "";
  let list = [];
  let noborder = false;
  let bgColor = "";
  let textColor = "";
  let fullwidth = false;
  let dark = true;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";

  let unsplashImages = [
    "https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1504643220124-8317cca212bd?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1696664754572-c8b52a7fa917?q=80&w=2815&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1498758536662-35b82cd15e29?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTJ8fHdvcmtpbmclMjBvbiUyMGxhcHRvcHxlbnwwfHwwfHx8Mg%3D%3D",
    "https://images.unsplash.com/photo-1633113215988-4eaddc3965d9?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1545184180-25d471fe75eb?q=80&w=1861&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1598221860268-4c711f099b6d?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1507537297725-24a1c029d3ca?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1524758870432-af57e54afa26?q=80&w=2558&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1584213447128-7d708614348c?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1684582532345-203f59690241?q=80&w=2727&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];
  let imageFromUrl = "";

  const [imageIndex, setImageIndex] = useState(0);

  // set image index to random number between 0 and unsplashImages.length
  useEffect(() => {
    setImageIndex(Math.floor(Math.random() * unsplashImages.length));
  }, []);

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "list") {
      list.push(myDecodeURIComponent(value));
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "image") {
      imageFromUrl = myDecodeURIComponent(value);
    } else if (key === "light") {
      dark = false;
    } else if (key === "bgcolor") {
      bgColor = myDecodeURIComponent(value);
    } else if (key === "textcolor") {
      textColor = myDecodeURIComponent(value);
    }
  });

  list = list.filter((x) => x);

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className={`w-1/2 flex-1 ${
          bgColor ? "" : dark ? "bg-blue-800" : "bg-neutral-100"
        } p-10 flex flex-col justify-center`}
        style={{
          ...(bgColor ? { backgroundColor: `#${bgColor}` } : {}),
        }}
      >
        <h1
          className={`text-2xl font-black leading-none ${
            textColor ? "" : dark ? "text-white" : "text-black"
          }`}
          style={{
            ...(textColor ? { color: `#${textColor}` } : {}),
          }}
        >
          {heading}
        </h1>

        <div className="flex flex-col space-y-4 mt-6">
          {list.map((item, index) => {
            return (
              <div className="flex gap-2">
                <div className="mr-2" style={{ paddingTop: "1px" }}>
                  <span
                    className={`${
                      textColor ? "" : dark ? "text-blue-400" : "text-black"
                    } text-xl leading-none`}
                    style={{
                      ...(textColor ? { color: `#${textColor}` } : {}),
                    }}
                  >
                    <i className="ri-arrow-right-line"></i>
                  </span>
                </div>
                <div
                  className={`${
                    textColor ? "" : dark ? "text-white" : "text-black"
                  } text-xl font-bold leading-tight`}
                  style={{
                    ...(textColor ? { color: `#${textColor}` } : {}),
                  }}
                >
                  <span className="leading-none">{item}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-1/2  relative overflow-hidden">
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <div className="relative flex-grow h-full">
            <img
              className="absolute bottom-0 right-5"
              style={{
                height: "50px",
                objectFit: "contain",
                width: "120px",
                zIndex: 10,
                objectPosition: "center",
              }}
              src={logo}
            />
            <div className="absolute top-0 left-0 right-0 bottom-0">
              <img
                src={imageFromUrl || unsplashImages[imageIndex]}
                className="w-full object-cover object-center h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateListUnsplash;
